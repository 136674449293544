import { useState, useEffect, useRef } from "react";
import "./App.css";
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import Cookies from "js-cookie";


const GOOGLE_MAPS_API_KEY = "AIzaSyC5mq7lvqgjdvHtrzl6dK-zjA_Oa3qtdDQ";

const customIcon = (size = [25, 40]) => new L.Icon({
  iconUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  iconSize: size,
  iconAnchor: [size[0] / 2, size[1]], // Centered horizontally, bottom vertically
  popupAnchor: [0, -size[1] / 2]
});

function loadGoogleMapsScript(callback) {
  if (window.google && window.google.maps) {
    callback();
    return;
  }
  const script = document.createElement("script");
  script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
  script.async = true;
  script.defer = true;
  script.onload = callback;
  script.onerror = () => console.error("Google Maps API script failed to load.");
  document.body.appendChild(script);
}

function TimeCard({ city, offset, onRemove, isHighlighted, onHover }) {
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");

  useEffect(() => {
    const updateTime = () => {
      const now = new Date().toLocaleString("en-US", {
        timeZone: city.timezoneId,
        hour: "2-digit",
        minute: "2-digit",
      });

      const formattedDate = new Date().toLocaleString("en-US", {
        timeZone: city.timezoneId,
        weekday: "short",
        month: "short",
        day: "2-digit",
        year: "numeric"
      });

      setTime(now);
      setDate(formattedDate);
    };

    updateTime(); // Initialize the first render
    const interval = setInterval(updateTime, 1000); // Update every second

    return () => clearInterval(interval);
  }, [city.timezoneId]);

  return (
    <div className={`time-card ${isHighlighted ? "highlight" : ""}`}
      onMouseEnter={() => onHover(city.name)}
      onMouseLeave={() => onHover(null)}>
      <div className="time-card-content">
        <span className="city-name">{city.name}</span>
        <span className="city-time">{time}</span>
        <span className="city-date">{date}</span>
      </div>
      <button className="remove-button" onClick={() => onRemove(city.name)}>✕</button>
    </div>
  );
}


// function Menu() {
//   const [isOpen, setIsOpen] = useState(false);
//   const menuRef = useRef(null);

//   useEffect(() => {
//     function handleClickOutside(event) {
//       if (menuRef.current && !menuRef.current.contains(event.target)) {
//         setIsOpen(false);
//       }
//     }
//     if (isOpen) {
//       document.addEventListener("mousedown", handleClickOutside);
//     } else {
//       document.removeEventListener("mousedown", handleClickOutside);
//     }
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [isOpen]);

//   return (
//     <div ref={menuRef}>
//       <button onClick={() => setIsOpen(!isOpen)} style={{
//         position: "absolute",
//         top: "10px",
//         left: "10px",
//         zIndex: 1000,
//         background: "none",
//         border: "none",
//         padding: "0",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         cursor: "pointer"
//       }}>
//         <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
//           <path d="M4 18L20 18" stroke="#000000" stroke-width="2" stroke-linecap="round" />
//           <path d="M4 12L20 12" stroke="#000000" stroke-width="2" stroke-linecap="round" />
//           <path d="M4 6L20 6" stroke="#000000" stroke-width="2" stroke-linecap="round" />
//         </svg>
//         <span style={{ marginLeft: "10px", fontSize: "20px", fontWeight: "bold", display: "flex", alignItems: "center" }}
//           onMouseEnter={(e) => e.target.style.textDecoration = "underline"}
//           onMouseLeave={(e) => e.target.style.textDecoration = "none"}>More Tools</span>
//       </button>
//       <div style={{
//         position: "fixed",
//         top: 0,
//         left: isOpen ? "0" : "-25%",
//         width: "10%",
//         height: "100%",
//         backgroundColor: "white",
//         boxShadow: "2px 0px 5px rgba(0,0,0,0.3)",
//         zIndex: 999,
//         padding: "20px",
//         transition: "left 0.5s ease-in-out, opacity 0.5s ease-in-out",
//         opacity: isOpen ? 1 : 0,
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "flex-start",
//         paddingTop: "3%"
//       }}>
//         <a href="https://worldtime.alluseful.tools" style={{ display: "block", marginBottom: "20px", fontSize: "22px", textDecoration: "none", color: "black", transition: "text-decoration 0.3s ease-in-out" }}
//           onMouseEnter={(e) => e.target.style.textDecoration = "underline"}
//           onMouseLeave={(e) => e.target.style.textDecoration = "none"}>
//           World Time
//         </a>
//         <a href="https://crypto.alluseful.tools" style={{ display: "block", fontSize: "22px", textDecoration: "none", color: "black", transition: "text-decoration 0.3s ease-in-out", marginTop: "3px" }}
//           onMouseEnter={(e) => e.target.style.textDecoration = "underline"}
//           onMouseLeave={(e) => e.target.style.textDecoration = "none"}>
//           Crypto
//         </a>
//       </div>
//     </div>
//   );
// }

function LocationClickHandler({ onLocationSelect }) {

  function getCityAndCountry(geocodeResults) {
    let city = "undefined";
    let country = "";
    for (const result of geocodeResults) {
      for (const component of result.address_components) {
        if (component.types.includes("locality")) {
          city = component.long_name;
        } else if (component.types.includes("administrative_area_level_1")) {
          city = component.long_name;
        }
        if (component.types.includes("country")) {
          country = component.long_name;
        }
      }
      if (city !== "undefined" && country) break;
    }
    return city !== "undefined" ? `${city}, ${country}` : "undefined";
  }
  useMapEvents({
    click: async (e) => {
      let { lat, lng } = e.latlng;

      // Normalize longitude to map into the -180 to 180 range
      lng = ((lng + 180) % 360 + 360) % 360 - 180;

      // find city name
      const response_geocode = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAPS_API_KEY}`
      );
      const data_geocode = await response_geocode.json();

      // fine zone id
      const response_zone = await fetch(
        `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${Math.floor(Date.now() / 1000)}&key=${GOOGLE_MAPS_API_KEY}`
      );
      const data_zone = await response_zone.json();

      if (data_geocode.status === "OK" && data_zone.status === "OK" && data_geocode.results.length > 0) {
        console.log(data_geocode.results)
        const cityName = getCityAndCountry(data_geocode.results);
        if (cityName === "undefined") {
          showTemporaryMessage("No city exist at selected location.", 2000);
          return;
        }
        const zoneId = data_zone.timeZoneId;
        onLocationSelect({ name: cityName, timezoneId: zoneId, lat, lng });
      }
    },
  });
  return null;
}

function showTemporaryMessage(message, duration) {
  const msgDiv = document.createElement("div");
  msgDiv.textContent = message;
  msgDiv.style.position = "fixed";
  msgDiv.style.top = "50%";
  msgDiv.style.left = "50%";
  msgDiv.style.transform = "translateX(-50%)";
  msgDiv.style.backgroundColor = "rgba(0, 0, 0, 0.7)";
  msgDiv.style.color = "white";
  msgDiv.style.padding = "10px 20px";
  msgDiv.style.borderRadius = "5px";
  msgDiv.style.zIndex = "1000";

  document.body.appendChild(msgDiv);

  setTimeout(() => {
    document.body.removeChild(msgDiv);
  }, duration);
}

function MapCursorHandler() {
  useMapEvents({
    mouseover: (e) => {
      e.target._container.style.cursor = "default";
    },
    mouseout: (e) => {
      e.target._container.style.cursor = "default";
    }
  });
  return null;
}

function App() {
  const [selectedCities, setSelectedCities] = useState([]);
  const [highlightedCity, setHighlightedCity] = useState(null);
  const autocompleteRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    document.title = "World Time";

    const savedCities = Cookies.get("selectedCities");
    if (savedCities) {
      try {
        const parsedCities = JSON.parse(savedCities);
        if (Array.isArray(parsedCities)) {
          setSelectedCities(parsedCities);
        }
      } catch (error) {
        console.error("Failed to parse cities from cookies:", error);
      }
    }

    const interval = setInterval(() => {
      if (window.google && window.google.maps && window.google.maps.places) {
        clearInterval(interval);
        loadGoogleMapsScript(() => {
          autocompleteRef.current = new window.google.maps.places.Autocomplete(
            inputRef.current,
            { types: ["(cities)"] }
          );
          autocompleteRef.current.addListener("place_changed", handlePlaceSelect);
        });
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (selectedCities.length > 0) {
      Cookies.set("selectedCities", JSON.stringify(selectedCities), { expires: 7 });
    }
  }, [selectedCities]);

  async function handlePlaceSelect() {
    if (!autocompleteRef.current) return;
    const place = autocompleteRef.current.getPlace();
    if (!place || !place.geometry) return;
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    if (lat === undefined || lng === undefined) return;
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${Math.floor(Date.now() / 1000)}&key=${GOOGLE_MAPS_API_KEY}`
    );
    const data = await response.json();
    if (data.status === "OK") {
      const newCity = {
        name: place.formatted_address,
        timezoneId: data.timeZoneId,
        lat: lat,
        lng: lng
      };
      setSelectedCities((prevCities) => [...prevCities, newCity]);
    }
    inputRef.current.value = "";
  }

  const handleRemoveCity = (cityName) => {
    setSelectedCities(selectedCities.filter(city => city.name !== cityName));
  };

  const handleLocationSelect = async (location) => {
    if (selectedCities.length >= 10) {
      showTemporaryMessage("You can add a maximum of 10 cities.", 2000);
      return;
    }
    if (selectedCities.some(city => city.name === location.name)) {
      showTemporaryMessage("You already added this city.", 2000);
      return;
    }
    setSelectedCities((prevCities) => [...prevCities, location]);
  };

  const [panelWidth, setPanelWidth] = useState(750);
const isResizing = useRef(false);
const startX = useRef(0);
const startWidth = useRef(0);

const startResizing = (e) => {
  isResizing.current = true;
  startX.current = e.clientX; // Store initial click position
  startWidth.current = panelWidth; // Store initial width
  document.addEventListener("mousemove", handleResizing);
  document.addEventListener("mouseup", stopResizing);
};

const stopResizing = () => {
  isResizing.current = false;
  document.removeEventListener("mousemove", handleResizing);
  document.removeEventListener("mouseup", stopResizing);
};

const handleResizing = (e) => {
  if (!isResizing.current) return;

  const deltaX = e.clientX - startX.current; // Calculate difference
  const newWidth = Math.max(200, Math.min(window.innerWidth - 200, startWidth.current + deltaX));

  setPanelWidth(newWidth);
};

  return (
    <div className="container">
      {/* <Menu /> */}
      <div className="left-panel" style={{
        width: `${panelWidth}px`,
        display: 'flex',
        flexDirection: 'column',
        height: '95%',
        backgroundColor: '#f2f2f2',
        overflowX: 'hidden'
      }}>
        <img src="slogan.png" alt="Slogan" className="title" style={{ maxWidth: "30%", height: "auto", marginBottom: "20px"}} />
        <input type="text" ref={inputRef} placeholder="Type city name here, or click on the map to select a city..." />
        <div className="city-list">
          {selectedCities.map((city) => (
            <TimeCard
              key={city.name}
              city={city}
              offset={city.offset}
              onRemove={handleRemoveCity}
              isHighlighted={highlightedCity === city.name}
              onHover={setHighlightedCity}
            />
          ))}
        </div>
        <footer className="footer" style={{
          marginTop: 'auto',
          padding: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <span style={{ textAlign: 'left', color: 'grey' }}>© 2025 alluseful.tools</span>
          <div style={{ width: '30%' }}>
            <a href="privacy.html" style={{ textAlign: 'right', textDecoration: 'none', color: 'grey' }}>Privacy</a>
            &nbsp;&#8226;&nbsp;
            <a href="term.html" style={{ textAlign: 'right', textDecoration: 'none', color: 'grey' }}>Terms & Conditions</a>
          </div>
          <a href="help.html" style={{ width: '30%', textAlign: 'right', textDecoration: 'none', color: 'blue' }}>How to use this website</a>

        </footer>
      </div>

      <div // Divider between left and right panel
        style={{
          width: "5px",
          cursor: "col-resize",
          backgroundColor: "rgb(150, 150, 150)"
        }}
        onMouseDown={startResizing}
      />

      <div className="right-panel">
        <MapContainer center={[20, 0]} zoom={2} style={{ height: "calc(100% - 30px)", width: "100%" }}
          minZoom={2}  // Prevent zooming out too far
          maxZoom={7}  // Prevent excessive zooming in
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&copy; OpenStreetMap contributors"
          />
          <LocationClickHandler onLocationSelect={handleLocationSelect} />
          {selectedCities.filter(city => city.lat !== undefined && city.lng !== undefined).flatMap((city) =>
            [-2, -1, 0, 1, 2].map((offset) => ( // Duplicate markers at multiple longitudes
              <Marker
                key={`${city.name}-${offset}`}
                position={[city.lat, city.lng + offset * 360]} // Adjust longitude for repeated world copies
                icon={highlightedCity === city.name ? customIcon([25, 35]) : customIcon([20, 30])}
                eventHandlers={{
                  mouseover: () => setHighlightedCity(city.name),
                  mouseout: () => setHighlightedCity(null)
                }}
              >
                <Popup>{city.name}</Popup>
              </Marker>
            ))
          )}
          <MapCursorHandler />

        </MapContainer>

      </div>
    </div>
  );
}

export default App;
